// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-d09220f2]{font-family:\"Open Sans\"}.font-header[data-v-d09220f2]{font-family:\"Hero\"}.usp-box[data-v-d09220f2],.usp-box-icon[data-v-d09220f2]{display:flex;justify-content:center;align-items:center}.usp-box[data-v-d09220f2]{flex-wrap:wrap;width:100%;border-radius:10px;border:1px solid #01685e;background:#f9fafc;box-sizing:border-box}@media screen and (max-width:600px){.usp-box[data-v-d09220f2]{padding:12px}}@media screen and (min-width:600px){.usp-box[data-v-d09220f2]{padding:12px}}@media screen and (min-width:900px){.usp-box[data-v-d09220f2]{padding:15px}}@media screen and (max-width:600px){.usp-box[data-v-d09220f2]{margin-bottom:16px}}@media screen and (min-width:600px){.usp-box[data-v-d09220f2]{margin-bottom:16px;margin-bottom:20px}}.usp-box__icon[data-v-d09220f2]{padding:10px;border-radius:50px;background:#fff;border:1px solid #01685e;width:60px;height:60px;box-sizing:border-box;color:#01685e}@media screen and (max-width:600px){.usp-box__icon[data-v-d09220f2]{padding:12px}}@media screen and (min-width:600px){.usp-box__icon[data-v-d09220f2]{padding:12px}}@media screen and (min-width:900px){.usp-box__icon[data-v-d09220f2]{padding:15px}}.usp-box__content[data-v-d09220f2]{flex:1;padding-left:20px;display:grid;grid-gap:5px;gap:5px;grid-template-columns:repeat(1,1fr)}.usp-box__content span[data-v-d09220f2]{display:block;font-size:14px;line-height:22px}.usp-box__content span[data-v-d09220f2]:first-child{font-weight:700;color:#01685e}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

<template>
    <div class="form-container__input">
        <span>
            <label :for="formKey">{{ label }}</label>
            <tooltip v-if="tooltipText" :tooltip-text="tooltipText" />
        </span>
        <input
            v-model="input"
            type="text"
            required
            :name="formKey"
            :placeholder="placeholder"
            @keypress="onlyNumbers($event)"
        >
        <div
            v-if="error"
            class="form-container__error"
        >
            {{ errorText }}
        </div>
    </div>
</template>

<script>
import Tooltip from '@/components/features/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        allowOnlyNumbers: {
            type: Boolean,
            required: false,
            default: false
        },
        formKey: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        errorText: {
            type: String,
            required: false,
            default: String
        },
        allowDash: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            input: ''
        };
    },
    watch: {
        input() {
            this.$emit('set', this.formKey, this.input);
        }
    },
    mounted() {
        this.$emit('set', this.formKey, this.input);
    },
    methods: {
        onlyNumbers ($event) {
            if (this.allowOnlyNumbers) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

                if (this.allowDash) {

                    if (keyCode < 48 && keyCode !== 45 || keyCode > 57 && keyCode !== 45) {
                        $event.preventDefault();
                    }
                }

                else {
                    if (keyCode < 48 || keyCode > 57) {
                        $event.preventDefault();
                    }
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    section {}
</style>

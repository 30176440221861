<template>
    <div class="form-container__input">
        <div class="head">
            <label>{{ label }}</label>
            <span>{{ $format.currency(sliderValue) }}</span>
        </div>

        <range-slider
            v-model="sliderValue"
            class="slider"
            :min="min"
            :max="max"
            :step="step"
        />
    </div>
</template>

<script>
import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';
export default {
    components: { RangeSlider },
    props: {
        formKey: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        min: {
            type: Number,
            required: false,
            default: 5000
        },
        max: {
            type: Number,
            required: false,
            default: 60000
        },
        step: {
            type: Number,
            required: false,
            default: 5000
        },
        startValue: {
            type: Number,
            required: false,
            default: 20000
        }
    },
    data () {
        return {
            sliderValue: this.startValue
        };
    },
    watch: {
        sliderValue() {
            this.$emit('set', this.formKey, this.sliderValue);
        }
    },
    mounted() {
        this.$emit('set', this.formKey, this.sliderValue);
    }
};
</script>

<style lang="scss" scoped>
.form-container__input::v-deep {
    .head {
        @include flex(between);
    }

    .range-slider {
        padding: 0;
        width: 100%;
    }

    .range-slider-knob {
        background: $green;
        border: 4px solid $white;
        @include size(30px);
        box-shadow: none;
    }

    .range-slider-rail,
    .range-slider-fill {
        height: 6px;
    }

    .range-slider-fill {
        background: $green;
    }
}
</style>

<template>
    <div class="expand-row">
        <div
            class="expand-row__button"
            :class="{expand: expand}"
            @click="toggle()"
        >
            <i v-if="leftIcon" class="left-icon" :class="leftIcon" />
            {{ button }}
            <i class="right-icon" :class="rightIcon" />
        </div>

        <div
            ref="expand"
            :class="[{expand: show, hidden: !show}]"
            class="expand-row__content"
            :style="{ height }"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        button: {
            type: String,
            required: true,
            default: String
        },
        leftIcon: {
            type: String,
            required: false,
            default: String
        },
        rightIcon: {
            type: String,
            required: false,
            default: 'fa-solid fa-chevron-down'
        }
    },
    data() {
        return {
            expand: false,
            height: '0',
            show: true,
            timeout: null
        };
    },
    methods: {
        showBody() {
            this.height = `${this.$refs.expand.scrollHeight}px`;
            this.show = true;
            // clearTimeout(this.timeout);
            this.$nextTick(() => {
                this.timeout = setTimeout(() => {
                    this.height = '';
                }, 250);
            });
        },
        hideBody() {
            this.height = `${this.$refs.expand.scrollHeight}px`;
            this.show = false;
            // clearTimeout(this.timeout);
            this.$nextTick(() => {
                this.timeout = setTimeout(() => {
                    this.height = '0px';
                });
            }, 0);
        },
        toggle() {
            if (!this.expand) {
                this.expand = true;
                this.showBody();
            }
            else {
                this.expand = false;
                this.hideBody();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.expand-row {
    @include border(bottom);
    &:last-child {
        border: 0;
        .expand-row__content { padding-bottom: 0; }
    }

    &__button {
        @include flex(between);
        @extend .font-text;
        cursor: pointer;
        font-weight: bold;
        @include font-size(14px);
        @include spacing(padding,3,(top,bottom));
        i { transition: .3s ease-in-out; }
    }

    &__button.expand {
        color: $purple;
        i { transform: rotate(180deg) }
    }

    &__content::v-deep {
        background: $white;
        overflow: hidden;
        transition: height 500ms ease;
        box-sizing: border-box;


        p { @include font-size(14px,8px); }
    }
    &__content.hidden {
        height: 0;
    }
}
</style>

<template>
    <div 
        class="form-container__input" 
        :class="{focus: expand}"
    >
        <label :for="name">{{ label }}</label>
        <div
            :class="{expand: expand}"
            class="input-select"
        >
            <div
                class="input"
                tabindex="0"
                @click="expand = true"
                @focus="expand = true"
                @blur="expand = false"
            >
                <span v-if="!input">Välj...</span>

                {{ input }}
            </div>

            <ul
                v-if="expand"
                class="input-select__dropdown"
            >
                <li
                    v-for="(option, index) in options"
                    :key="index"
                    :class="{index: listIndex === index}"
                    @mousedown="setInput(option)"
                >
                    {{ option.name }}
                </li>
            </ul>
        </div>

        <div
            v-if="expand"
            class="input-select__overlay"
            @click="expand = false"
        />

        <div
            v-if="error"
            class="form-container__error"
        >
            {{ errorText }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        formKey: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: false,
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        errorText: {
            type: String,
            required: false,
            default: String
        },
        options: {
            type: Array,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            input: '',
            listIndex: 0,
            expand: false
        };
    },
    watch: {
        expand() {
            this.listIndex = 0;

            const bodyElement = document.querySelector('body')
            if (this.expand) {
                bodyElement.classList.add('a');
            }

            else {
                bodyElement.classList.remove('a'); 
            }
        }
    },
    created() {
        if (process.client) {
            document.addEventListener('keydown', (e) => {
                let maxIndex = this.options.length;
                // arrow down
                if (e.keyCode === 40) {
                    if (this.options.length && this.listIndex < maxIndex) {
                        this.listIndex++;
                    }
                }
                // arrow up
                if (e.keyCode === 38) {
                    if (this.listIndex > 0) {
                        this.listIndex--;
                    }
                }
                // enter
                if (e.keyCode === 13 && this.expand) {
                    let options = this.options;
                    for (let i = 0; i < options.length; i++) {
                        if (i === this.listIndex) {
                            this.setInput(options[i]);
                            return;
                        }
                    }
                }
            });
        }
    },
    methods: {
        setInput(input) {
            this.input = input.name;
            this.$emit('set', this.formKey, input.value);
            this.expand = false;
        }
    }
};
</script>

<style lang="scss" scoped>
    .form-container__input {
        outline: none;
        position: relative;
    }

    .input-select {
        position: relative;
        z-index: 2;

        .input {
            height: 48px;
            cursor: pointer;
            background: $graybg;
            outline: none;
            @include border(right,4px,#e2e2e2);
            padding: 10px;
            font-family: $fontText;
            @include font-size(16px,8px);

            span,
            i {
                font-size: 12px;
                opacity: .7;
            }

            span { margin-right: 10px; }
        }

        &__dropdown {
            background: $graybg;
            width: 100%;
            @include border-radius(5px,(bottom-left,bottom-right));
            box-shadow: $bxs;

            li {
                cursor: pointer;
                padding: 10px;
                font-size: 14px;
                @include border(top);
                &:hover { background: #e6e6e6; }
                &:last-child {
                    @include border-radius(5px,(bottom-left,bottom-right));
                }
            }

            li.index {
                background: $green;
                color: $white;
            }
        }

        &__overlay {
            z-index: 1;
            position: fixed;
            top: 0;
            left: 0;
            @include size(100vw,100vh);
        }
    }

    .input-select.expand {
        box-shadow: $bxs;
        @include border-radius(10px,(bottom-left, bottom-right));
        .input {
            border-color: $green;
        }
    }
    .input-select.input {
        .input {
            border-color: $green;
        }
    }
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-4ce5dc3a]{font-family:\"Open Sans\"}.font-header[data-v-4ce5dc3a]{font-family:\"Hero\"}.form-container__input[data-v-4ce5dc3a]{outline:none;position:relative}.input-select[data-v-4ce5dc3a]{position:relative;z-index:2}.input-select .input[data-v-4ce5dc3a]{height:48px;cursor:pointer;background:#f9fafc;outline:none;border:0;box-sizing:border-box;border-right:4px solid #e2e2e2;padding:10px;font-family:\"Open Sans\";font-size:16px;line-height:24px}.input-select .input i[data-v-4ce5dc3a],.input-select .input span[data-v-4ce5dc3a]{font-size:12px;opacity:.7}.input-select .input span[data-v-4ce5dc3a]{margin-right:10px}.input-select__dropdown[data-v-4ce5dc3a]{background:#f9fafc;width:100%;border-bottom-left-radius:5px;border-bottom-right-radius:5px;box-shadow:0 10px 15px rgba(169,177,200,.25)}.input-select__dropdown li[data-v-4ce5dc3a]{cursor:pointer;padding:10px;font-size:14px;border:0;box-sizing:border-box;border-top:1px solid rgba(241,214,245,.7)}.input-select__dropdown li[data-v-4ce5dc3a]:hover{background:#e6e6e6}.input-select__dropdown li[data-v-4ce5dc3a]:last-child{border-bottom-left-radius:5px;border-bottom-right-radius:5px}.input-select__dropdown li.index[data-v-4ce5dc3a]{background:#01685e;color:#fff}.input-select__overlay[data-v-4ce5dc3a]{z-index:1;position:fixed;top:0;left:0;width:100vw;height:100vh}.input-select.expand[data-v-4ce5dc3a]{box-shadow:0 10px 15px rgba(169,177,200,.25);border-bottom-left-radius:10px;border-bottom-right-radius:10px}.input-select.expand .input[data-v-4ce5dc3a],.input-select.input .input[data-v-4ce5dc3a]{border-color:#01685e}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

<template>
    <div class="usp-box">
        <i class="usp-box__icon usp-box-icon fa-regular fa-award fa-2xl" />

        <div class="usp-box__content">
            <span>{{ uspBoxTitle }}</span>
            <span>{{ $prismic.asText(uspBoxDescription) }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        uspBoxTitle: {
            type: String,
            required: true
        },
        uspBoxDescription: {
            type: Array,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.usp-box-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.usp-box {
    @include flex;
    width: 100%;
    @include spacing(padding,3);
    @include border-radius(10px);
    @include border(all,1px,$green);
    background: $graybg;
    @include spacing(margin,4,bottom);

    &__icon {
        padding: 10px;
        @include border-radius(50px);
        background: $white;
        @include border(all,1px,$green);
        @include size(60px);
        @include spacing(padding,3);
        color: $green;
    }

    &__content {
        flex: 1;
        padding-left: 20px;
        @include grid(1,5px);

        span {
            display: block;
            @include font-size(14px,8px);

            &:first-child {
                font-weight: bold;
                color: $green;
            }
        }
    }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-0b9f2b01]{font-family:\"Open Sans\"}.font-header[data-v-0b9f2b01]{font-family:\"Hero\"}.form-container__input[data-v-0b9f2b01] .head{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center}.form-container__input[data-v-0b9f2b01] .range-slider{padding:0;width:100%}.form-container__input[data-v-0b9f2b01] .range-slider-knob{background:#01685e;border:4px solid #fff;width:30px;height:30px;box-shadow:none}.form-container__input[data-v-0b9f2b01] .range-slider-fill,.form-container__input[data-v-0b9f2b01] .range-slider-rail{height:6px}.form-container__input[data-v-0b9f2b01] .range-slider-fill{background:#01685e}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
